<template>
  <LayoutBuyer>
    <template v-slot:title>MATERIALS AND SUPPLIES</template>
    <ProductList :vendor="false"></ProductList>
  </LayoutBuyer>
</template>

<script>
import ProductList from '@/components/Partial/Products/ProductList.vue';
import LayoutBuyer from '@/components/Partial/Layouts/LayoutBuyer/LayoutBuyer.vue';
export default {
  name: 'BuyerProductList',

  components: { ProductList, LayoutBuyer },
};
</script>

<style lang="scss" scoped></style>
